<template>
  <div class="row">
    <div class="card card-body printableArea">
      <h3>
        <b>Periode {{ $t("document_out_ready") }}</b>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12">
          <form method="post" @submit.prevent="filterData">
            <div class="form-body">
              <div class="row justify-content-md-center">
                  <div class="col-md-9 col-sm-10">
                    <div class="row">
                      <label class="control-label mt-2 col-md-5">Periode Mulai</label>
                      <div class="form-group" style="width: 320px">
                        <input
                          placeholder="Periode Mulai"
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYY-MM-DD"
                          v-model="start_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-md-9 col-sm-10">
                    <div class="row">
                      <label class="control-label mt-2 col-md-5">Periode Akhir</label>
                      <div class="form-group" style="width: 320px">
                        <input
                          placeholder="Periode Akhir"
                          type="date"
                          class="form-control"
                          data-date=""
                          data-date-format="YYYY-MM-DD"
                          v-model="end_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-md-9 col-sm-10">
                    <div class="row">
                      <label class="control-label mt-2 col-md-5">Nomor Surat</label>
                      <div class="form-group" style="width: 320px">
                        <input
                          placeholder="Nomor Surat"
                          class="form-control"
                          v-model="nomor_surat"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-md-9 col-sm-10">
                    <div class="row">
                      <label class="control-label mt-2 col-md-5">Perihal</label>
                      <div class="form-group" style="width: 320px">
                        <input
                          placeholder="Perihal"
                          class="form-control"
                          v-model="perihal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              <!-- <div class="row">
                <div class="pull-left">
                  <div class="form-group row">
                    <label class="control-label mt-2 col-md-5"
                      >Periode Surat</label
                    >
                    <div class="col-md-7">
                      <input
                        type="date"
                        class="form-control"
                        data-date=""
                        data-date-format="YYYY-MM-DD"
                        v-model="start_date"
                      />
                    </div>
                  </div>
                </div>

                <div class="pull-right">
                  <div class="form-group row">
                    <label class="control-label mt-2 col-md-5"
                      >&nbsp;&nbsp;&nbsp;&nbsp;s/d</label
                    >
                    <div class="col-md-7">
                      <input
                        type="date"
                        class="form-control"
                        data-date=""
                        data-date-format="YYYY-MM-DD"
                        v-model="end_date"
                      />
                    </div>
                  </div>
                </div>
              </div> -->


                <!-- <div class="row">
                  <div class="form-group row">
                    <div class="col-md-7">
                      <input
                        type="text"
                        placeholder="Pencarian Nomor Surat"
                        class="form-control"
                        v-model="nomor_surat"
                      />
                    </div>
                  </div>
                </div> -->

                <!-- <div class="row">
                  <div class="form-group row">
                    <div class="col-md-7">
                      <input
                        type="text"
                        placeholder="Pencarian Perihal Surat"
                        class="form-control"
                        v-model="perihal"
                      />
                    </div>
                  </div>
                </div> -->

            </div>
            <hr />
            <div class="form-actions">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-9">
                      <button
                        type="submit"
                        class="btn btn-success"
                        title="Simpan"
                      >
                        Submit</button
                      >&nbsp;
                      <button
                        type="button"
                        @click="resetInput()"
                        class="btn btn-inverse"
                        title="Reset"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>{{ $t("document_out_ready") }}</b>
            </div>
            <div class="col-md-2 col-12" v-if="canSeeAll">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="me">{{ $t("self") }}</option>
                <option value="all">{{ $t("one_unit") }}</option>
              </select>
            </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onCancel="doCancel"
              :onDetail="doDetail"
              :onEdit="doEdit"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidCancel="false"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidDelete="forbidDel"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import moment from "moment";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let last = localStorage.getItem("last_request");
    last = last != null ? last.split("?") : ",?,".split("?");
    let params = {};
    if (last[0].includes("document_out")) {
      params = JSON.parse(last[1]);
    }
    if (params.approval == undefined || params.approval != 2) {
      params = {};
    }
    return {
      search: params.search || "",
      from: params.from || "me",
      start_date:
        params.start_date || moment().startOf("year").format("YYYY-MM-DD"),
      end_date: params.end_date || moment().endOf("year").format("YYYY-MM-DD"),
      perihal: "",
      nomor_surat: "",
      nomor_kendali: "",
      headers: {
        no_opd: {
          label: this.$t("document_number"),
          sortable: false,
        },
        subject: {
          label: this.$t("subject"),
          sortable: true,
        },
        document_date: {
          label: this.$t("date"),
          sortable: true,
          type: "date",
        },

        receiver: {
          label: this.$t("maker") + "/" + this.$t("to"),
          sortable: true,
          tooltip: "receiverTooltip",
        },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.documentAlreadySend.items !== undefined) {
        if (this.$store.state.documentAlreadySend.items.items !== undefined) {
          if (
            !Array.isArray(this.$store.state.documentAlreadySend.items.items) &&
            this.$store.state.documentAlreadySend.items.items !== undefined
          ) {
            data = Object.keys(
              this.$store.state.documentAlreadySend.items.items
            ).map((i) => this.$store.state.documentAlreadySend.items.items[i]);
          } else {
            data = this.$store.state.documentAlreadySend.items.items;
          }
        }
      }
      data.map((element) => {
        element.isBold = true;
      });
      return data;
    },
    filters() {
      let filt = {};
      if (this.$store.state.documentAlreadySend.items !== undefined) {
        if (this.$store.state.documentAlreadySend.items.filters !== undefined) {
          filt = this.$store.state.documentAlreadySend.items.filters;
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.documentAlreadySend;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_out) {
        return !this.$store.state.profile.permissions.document_out.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_out) {
        return !this.$store.state.profile.permissions.document_out.read;
      }
      return false;
    },
    forbidDel() {
      return true;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.document_out_all) {
        return this.$store.state.profile.permissions.document_out_all.read;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      from: this.from,
      search: this.search,
      start_date: this.start_date,
      end_date: this.end_date,
      perihal: this.perihal,
      nomor_surat: this.nomor_surat,
      nomor_kendali: this.nomor_kendali,
    };
    this.$store.commit("documentAlreadySend/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    resetInput() {
      this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.perihal = "";
      this.nomor_surat = "";
      this.nomor_kendali = "";
      const state = {
        loaded: false,
        items: [],
        search: this.search,
        from: this.from,
        start_date: this.start_date,
        end_date: this.end_date,
        perihal: this.perihal,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
      };
      this.$store.commit("documentAlreadySend/STATE", state);
      this.get(state);
    },
    filterData: function (event) {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        perihal: this.perihal,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
      };
      this.$store.commit("documentAlreadySend/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("documentAlreadySend/getDocumentAlreadySend", val);
    },
    doDetail(val) {
      this.$store.dispatch("documentAlreadySend/onDetail", val);
    },
    doCancel(val) {
      this.$store.dispatch("documentAlreadySend/onRetract", val);
    },
    doEdit(val) {
      this.$store.dispatch("documentAlreadySend/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("documentAlreadySend/submitDelete", val);
    },
    getFrom() {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        start_date: this.start_date,
        end_date: this.end_date,
        perihal: this.perihal,
        nomor_surat: this.nomor_surat,
        nomor_kendali: this.nomor_kendali,
      };
      this.$store.commit("documentAlreadySend/STATE", state);
      this.get(state);
    },
  },
};
</script>
